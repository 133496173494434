import { useSnapshot } from 'valtio';
import { useOpbsResource } from '~/modules/options/api/useOpbsResource';
import { optionAnalyzeStore } from './optionAnalyzeStore';
//
export const useOptionSimpleProfit = () => {
    const state = useSnapshot(optionAnalyzeStore);
    /** 預設的選擇權契約代號 */
    const contract = state.currentContract;
    // /** 拿'所有'選擇權報價 */
    // const getQuote = useMarketPriceResource(contract, dayAPI())
    /** Signalr */
    //const signalr = useSignalrStoreValueOHLC(state => state.value)
    // useEffect(() => {
    //   useSignalrStore.getState().subscribeAdd([...(getQuote.map(s => s.symbol) || [])], 'ohlc')
    //   return () => {
    //     useSignalrStore.getState().subscribeRemove([...(getQuote.map(s => s.symbol) || [])], 'ohlc')
    //   }
    // }, [])
    /** 目前合約 給出type 周別 月別 (用於filterSocket過濾買賣權函式) */
    // const contractObject = useParseOptionsContractMonthString(contract)
    // /** 將已取得報價過濾出`買權` */
    // const callSource = filter(signalr, datum => filterSocket(datum, 'CALL', contractObject))
    // /** 將已取得報價過濾出`賣權` */
    // const putSource = filter(signalr, datum => filterSocket(datum, 'PUT', contractObject))
    /**
     * ToDo: 如果以有些履約價沒有 大戶成均價 則不會有履約價 導致chart圖表的略約價會空的 計算dot是錯的 ['15100', 這裡～～～～ '15450', '15500',
     * '15550', '15600', '15650', '15700', '15750']
     */
    const opbs = useOpbsResource({
        from: state.contractStartDateTime.clone(),
        to: state.intradayEndTime.clone(),
        contractMonth: contract,
        m_gte: 30000,
        q_gte: 10,
    }).res.data;
    //待抽
    const newCallData = opbs?.call?.name.map((symbol, index) => {
        const strikePrice = opbs.call?.name[index]?.replace('C', '') ?? 0;
        const price = opbs.call?.price[index] ?? 0;
        const avg_price = opbs.call?.avg_price[index] ?? 0;
        const lot = opbs.call?.lot[index] ?? 0;
        const changeLots = lot;
        const changeAmount = price * 50;
        const tradePrice = avg_price;
        const positionCost = tradePrice * 50 * changeLots * -1;
        const postionValue = price * 50 * lot; //(close)
        const positionProfit = positionCost + postionValue;
        const breakEevenPrice = Number(strikePrice) + tradePrice; //(call)
        const settlementProfit = (breakEevenPrice - Number(strikePrice)) * 50 * changeLots;
        return {
            strikePrice,
            changeLots,
            changeAmount,
            tradePrice,
            positionCost,
            postionValue,
            positionProfit,
            breakEevenPrice,
            settlementProfit,
        };
    });
    //待抽
    const newPutData = opbs?.put?.name.map((symbol, index) => {
        const strikePrice = opbs.put?.name[index]?.replace('P', '') ?? 0;
        const price = opbs.put?.price[index] ?? 0;
        const avg_price = opbs.put?.avg_price[index] ?? 0;
        const lot = opbs.put?.lot[index] ?? 0;
        const changeLots = lot;
        const changeAmount = price * 50;
        const tradePrice = avg_price;
        const positionCost = tradePrice * 50 * changeLots * -1;
        const postionValue = price * 50 * lot; //(close選擇權成交價)
        const positionProfit = positionCost + postionValue;
        const breakEevenPrice = Number(strikePrice) - tradePrice; //(put)
        const settlementProfit = (breakEevenPrice - Number(strikePrice)) * 50 * changeLots;
        return {
            //變化口數: lot /contract_month
            strikePrice,
            //變化金額: price * 50 /contract_month
            changeLots,
            //成交平均價格: avg_price /contract_month
            changeAmount,
            //交易成本: avg_price * 50元 * lot (* -1)
            tradePrice,
            //現在成本: price * 50點 * lot
            positionCost,
            //現在成本: price * 50點 * lot
            postionValue,
            //持倉損益: 交易成本 + 現在成本
            positionProfit,
            //損益兩平點: 履約價 - trade_price
            breakEevenPrice,
            //結算獲利金額: 損益兩平點 - (指定履約價) * 50元 * change_lots
            settlementProfit,
        };
    });
    // const newData =
    //   newCallData?.reduce((acc: ProfitData[], callObj) => {
    //     const matchingPut = newPutData?.find(putObj => putObj.strikePrice === callObj.strikePrice)
    //     if (matchingPut) {
    //       acc.push({
    //         strikePrice: callObj.strikePrice,
    //         changeLots: callObj.changeLots + matchingPut.changeLots,
    //         changeAmount: callObj.changeAmount + matchingPut.changeAmount,
    //         tradePrice: callObj.tradePrice + matchingPut.tradePrice,
    //         positionCost: callObj.positionCost + matchingPut.positionCost,
    //         postionValue: callObj.postionValue + matchingPut.postionValue,
    //         positionProfit: callObj.positionProfit + matchingPut.positionProfit,
    //         breakEevenPrice: callObj.breakEevenPrice + matchingPut.breakEevenPrice,
    //         settlementProfit: callObj.settlementProfit + matchingPut.settlementProfit,
    //       })
    //     }
    //     return acc
    //   }, []) ?? []
    return {
        call: newCallData,
        put: newPutData,
    };
};
